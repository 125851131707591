.skill-empty {
    background: var(--bg-dark);
    border: 1px;
    border-radius: 2px;
    box-shadow: inset 1px 1px 2px #050505;
    height: 64px;
    width: 64px;
}

.skill-empty-small {
    background: var(--bg-dark);
    border: 1px;
    border-radius: 2px;
    box-shadow: inset 1px 1px 2px #050505;
    height: 42px;
    width: 42px;
}

.skill-empty-glow {
    box-shadow: inset 0px 0px 4px 3px aliceblue
}
