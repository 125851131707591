
.skill-details {
    background: linear-gradient(var(--bg-dark), var(--bg-norm));
    box-shadow: 3px 3px 4px var(#050505);
    padding:15px;
    width: 400px;
}

.skill-detail {
    width: 100%;
    padding-left: 10px;
    color: darkgray;
}

.skill-detail-combo {
    display: inline-flex;
    gap: 5px;
    margin-left: 10px;
}

.skill-detail-group-content {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 20px;
    white-space: nowrap;
    color: darkgray
}

.skill-desc {
    width: 35%;
    display: inline-block;
}

.skill-content {
    width: 65%;
    display: inline-block;
}

.skill-title {
    font-weight: bold;
    font-size: 24px;
    color: darkgray;
}

.skill-subtitle {
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
    color: darkgray;
}

.skill-graph-title {
    color: white;
    font-weight: 400;
}
