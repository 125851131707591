.Controls-container {
    height: 90px;
    margin-bottom: 10px;
}

.Controls-action {
    cursor: pointer;
    padding: 10px 5px;
}

.Controls-action-disabled {
    padding: 10px 5px;
}

.Controls-action:active {
    box-shadow: inset 1px 1px 2px var(--bg-dark);
}

.Controls-action-active {
    box-shadow: inset 1px 1px 2px var(--bg-dark);
    color: darkgrey;
}

.controls-strict-text {
    color: #abb3bf;
}

.Controls-actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: 5px;
}

