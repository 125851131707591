.Help-Container {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Help-Container > div {
    width: 850px;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.Help-Content {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;

}