:root {
  --bg-light:#383c48;
  --bg-dark: #16171a;
  --bg-dark-transparent: rgba(22, 23, 26, .5);
  --bg-norm: #212226;
  width: max-content;
}

.App {
  text-align: center;
  min-width: 1200px;
  min-height: 100%;
  color: darkgray;
}

.bp4-dark .bp4-menu {
  background-color: var(--bg-dark);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pick-container-content {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 50% 50%;
  gap: 4px;
padding: 7px;
}

.picked-container-content {
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: 1fr;
  gap: 8px;
  justify-content: center;
  padding: 10px 10px;
}

.stat-container-content {
  display: grid;
  gap: 10px;
  justify-content: center;
  grid-template-columns: auto auto;
  padding-top: 10px;
  padding-bottom: 10px;
}


.mobile-support {
  display: none;
  color: darkgrey;
  position: fixed;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
}

.mobile-support > h1 {
  margin-top: 25px;
}

@media only screen and (max-width: 600px) {
    html, body {
      width: 100vw;
    }

    .mobile-support {
        display: unset;
    }
    .App {
      min-width: unset;
      width: 100vw;
    }
}