.hero-name-container {
    position: relative;
    background: var(--bg-dark);
}
.hero-name-container > div > input {
    height: 46px;
}

.hero-name-skills-glow {
    box-shadow: 0px 0px 4px 3px aliceblue
}

.hero-search-open-combo-left {
    position: absolute;
    color: darkgray;
    top: -5px;
    left: -20px;
    padding: 1px;
}

.hero-search-open-combo-right {
    position: absolute;
    color: darkgray;
    top: -5px;
    right: -10px;
    padding: 1px;
}

.hero-name-text {
    position: absolute;
    bottom: 3px;
    left: 0px;
    right: 0px;
    background: rgba(0,0,0,.5);
    text-align: center;
    box-shadow: inset 1px 1px 2px #050505;
    cursor: pointer;
}

.hero-name-skills-container {
    background-color: var(--bg-light);
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    gap: 3px;
}


.hero-search-combo-container {
    flex-direction: column;
    display: flex;
    gap: 3px;
}