.skill-overlay {
    background-color: rgba(10,20,10,.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: darkgray;
}

.skill-overlay-small {
    font-size: x-small;
}

.skill-pick-overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: x-small;
    background-color: rgba(10,20,10,.8);
    color: darkgrey;
    padding: 1px;
}
.skill-small {
    height: 42px;
    width: 42px;
}

.skill-scepter {
    position: absolute;
    bottom: -3px;
    left: 0;
}

.skill-shard {
    position: absolute;
    bottom: 3px;
    right: 0;
}

.skill-ags-container {
    background-color: rgba(10,20,10,.8);
    position:absolute;
    bottom:0px;
    left: 0;
    right: 0;
    height: 25px;

}
.skill > img {
    width: inherit;
}

.skill-edit {
    opacity: 0.5;
    filter: grayscale(0.5)
}

.skill-click {
    cursor: pointer;
}

.skill-picked {
    filter: grayscale(100%) brightness(.5);
    cursor: default !important;
}

.skill-loading {
    animation: pulse 1s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: inset 0 0 0 0 rgba(87, 84, 117, 0.7);
	}

	70% {
		box-shadow: inset 0 0 0 20px rgba(87, 84, 117, 0);
	}

	100% {
		box-shadow: insert 0 0 0 0 rgba(87, 84, 117, 0);
	}
}