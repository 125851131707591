.Draft-board {
    margin-top: 50px;
    padding-right: 50px;
    width: 100vw;
    padding: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.Draft-board > div {
    gap: 10px;
}

.Draft-board-player {
    display: flex;
    flex-direction: column;
    order:5;
}

.Draft-board-left {
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    order: 1;
}

.Draft-board-center {
    display: flex;
    flex-direction: column;
    width:fit-content;
    order: 2;
}

.Draft-board-right {
    display: flex;
    flex-direction: column;
    order: 3;
    margin-right: 40px;
}

.Draft-board-overview {
    display: flex;
    flex-direction: column;
    order: 4;
}

@media only screen and (max-width: 600px) {
    .Draft-board {
        flex-wrap: wrap;
        padding: 0px;
        width:420px;
        row-gap: 5px;
        justify-content: space-around;
        margin-top: 0px;
        transform-origin: top;
        margin-top: 25px;
    }

    .Draft-board-center {
        order: 1;
        flex-basis: 100%;
        margin-bottom: 10px;
    }

    .Draft-board-left {
        order: 2;
        flex-basis: 2;
        padding: 0;
        margin: 0;
    }

    .Draft-board-left > div, .Draft-board-right > div {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .Draft-board-right {
        order: 3;
        flex-basis: 2;
        padding: 0;
        margin: 0;
    }
}