.invoker-alert {
    width: 400px;
}

.invoker-skills {
    display: grid;
    grid-template-columns: repeat(5, auto);
    justify-content: space-around;
    margin: 10px;
    margin-top: 30px;
    gap: 10px;
}
.invoker-button {
    margin: 0 10px;
    margin-top: 30px;
}