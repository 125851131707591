.Gamestat-container {
    grid-column: 2 / span 1;
    grid-row: 1;
    color: darkgray;
    min-width: 100%;
}

.gamestat-turn-counter {
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: default;
}


.gamestat-turn-counter > h2 {
    margin: 0px;
}

.gamestat-content {
    display: inline-flex;
    width: 100%;
}

.gamestat-scorecard {
    flex-grow: 1;
    padding-top: 5px;
    cursor: default;
}

.gamestat-scorecard-label {
    text-transform: capitalize;
}
