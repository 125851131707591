.hero-search-container {
    display: inline-flex;
    gap: 2px;
    height: 46px;
}

.hero-name-input > input {
    height: 100%;
}


.hero-search-container > *:first-child {
    flex-grow: 3
}


.hero-search-container > * :last-child{
    width: 16px;
}