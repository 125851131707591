.skill-win {
    box-shadow: 0px 0px 4px 3px aliceblue;
    filter: brightness(130%) !important;
}

.skill-survive {
    box-shadow: 0px 0px 4px 3px #888
}

.skill-badge {
    position: absolute;
    display: inline-flex;
    padding: 2px 2px;
    height: 16px;
    top: 0;
    left: 0;
    width: 64px;
    /* background-color: var(--bg-dark); */
    /* opacity: .7; */
}

.skill-empty {
    box-shadow: inset 1px 1px 2px #050505;
    height: 64px;
    width: 64px;
}

.skill {

    filter: brightness(85%);
    position: relative;
    background: var(--bg-dark);
    border: 1px;
    border-radius: 2px;
    height: 64px;
    width: 64px;
}

.skill-click {
    cursor: pointer;
}

.skill-picked {
    filter: grayscale(75%);
    cursor: default !important;
}

.skill-loading {
    animation: pulse 1s infinite;
}

.skill-tile-popover {
    opacity: .9;
}



@keyframes pulse {
    0% {
        box-shadow: inset 0 0 0 0 rgba(87, 84, 117, 0.7);
    }

    70% {
        box-shadow: inset 0 0 0 20px rgba(87, 84, 117, 0);
    }

    100% {
        box-shadow: insert 0 0 0 0 rgba(87, 84, 117, 0);
    }
}